.navbarwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  min-height: 70px;
  left: 0;
  z-index: 999999;
  transition: .5s linear;
}

.navbarwrapper h6 {
  width: 100%;
  font-size: 32px;
  color: rgb(30 154 214 / 1) !important;
  font-weight: bold;
  padding: 20px;
  z-index: 1;
  background-color: #fff;
  text-align: center;

}

.navbarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: rgb(30 154 214 / 1) !important;
  height: 50px;
}

.navbarLarge {
  width: 100%;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  /* You can adjust the padding value as needed */
  padding-right: 2rem;
  /* You can adjust the padding value as needed */
  z-index: 10;
}

.navbarItems {
  display: none;
  /* Hidden by default */
  font-size: 1.125rem !important;
  gap: 1rem;
  /* Equivalent to text-lg */
  line-height: 1.75rem !important;
  font-weight: 500;
  /* Equivalent to font-medium */
  letter-spacing: 0.05em;
  /* Equivalent to tracking-wider */
  height: 3rem;
  /* Equivalent to h-12 */
  align-items: center;
  /* Equivalent to items-center */
  text-align: center;
  /* Equivalent to text-center */
  color: #fff !important;
  /* Equivalent to text-black */
}

.navbarItem {
  text-decoration: none;
  /* No underline by default */
  transform: scale(1);
  /* No scaling by default */
  transition-property: all;
  /* Apply transition to all properties */
  transition-duration: 0.1s;
  /* Transition duration of 100ms */
  filter: none;
  cursor: pointer;
  color: #fff !important;
  /* No drop shadow by default */
}

.navbarItem:hover {
  text-decoration: underline !important;
  /* Underline on hover (hover:underline) */
  transform: scale(1.05) !important;
  /* Scale up by 5% on hover (hover:scale-105) */
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2)) !important;
  /* Apply a drop shadow on hover */
}

.mobileToggle {
  height: 100vh;
  /* Equivalent to h-screen */
  width: 25%;
  /* Equivalent to w-3/12 (25% of the parent container) */
  backdrop-filter: blur(4px);
  /* Equivalent to backdrop-blur-sm */
}

@media (min-width: 768px) {
  .mobileToggle {
    width: 50%;
    /* Equivalent to md:w-1/2 (50% of the parent container on medium screens) */
  }
}

.navMobileItems {
  background-color: #1e9ad6;
  /* Equivalent to bg-[#1e9ad6] */
  color: white;
  /* Equivalent to text-white */
  font-size: 1rem;
  /* Equivalent to text-base */
  height: 100vh;
  /* Equivalent to h-screen */
  width: 75%;
  /* Equivalent to w-9/12 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Equivalent to shadow */
  display: flex;
  /* Equivalent to flex */
  flex-direction: column;
  /* Equivalent to flex-col */
  padding-top: 3rem;
  /* Equivalent to pt-12 */
}

.navMobileItem {
  padding-top: 1.25rem;
  /* Equivalent to py-5 (1.25rem = 20px) */
  padding-bottom: 1.25rem;
  /* Equivalent to py-5 (1.25rem = 20px) */
  padding-left: 2rem;
  /* Equivalent to px-8 (2rem = 32px) */
  padding-right: 2rem;
  /* Equivalent to px-8 (2rem = 32px) */
  font-weight: 600;
  /* Equivalent to font-semibold */
  border-bottom: 1px solid #fff;
  color: #fff !important;
  /* Equivalent to border-b */
}

@media (min-width: 768px) {
  .navMobileItems {
    display: none;
    width: 50%;
    /* Equivalent to md:w-1/2 */
  }
}

.custom-right-full {
  right: -100%;
}




.socialIconLarge {
  margin-right: 0.75rem;
  cursor: pointer;
  height: 1rem;
}

@media (min-width: 768px) {
  .socialIconLarge {
    height: 1.5rem
  }
}


.mobileSocialContainer {
  display: flex;
  /* Equivalent to flex */
  padding-top: 1.25rem;
  /* Equivalent to py-5 (1.25rem = 20px) */
  padding-bottom: 1.25rem;
  /* Equivalent to py-5 (1.25rem = 20px) */
  padding-left: 2rem;
  /* Equivalent to px-8 (2rem = 32px) */
  padding-right: 2rem;
  /* Equivalent to px-8 (2rem = 32px) */
}

@media (min-width: 768px) {
  .mobileSocialIcon {
    height: 2.5rem;
    /* Equivalent to md:h-10 */
  }
}

.mobileSocialIcon {
  height: 2rem;
  margin-right: 0.75rem;
  /* Equivalent to mr-3 */
  cursor: pointer;
  /* Equivalent to cursor-pointer */
}


@media (min-width: 992px) {
  .navbarItems {
    display: flex;
    /* Shown on large screens (lg:flex) */
    justify-content: space-between;
    /* Equivalent to gap-4 */
  }
}

.mobileToggleIcon {
  height: 1rem;
}

.navbarSmall {
  position: fixed;
  /* Fixed position */
  top: 0;
  /* Positioned at the top of the viewport */
  width: 100vw;
  /* Full width of the viewport */
  height: 100vh;
  /* Full height of the viewport */
  display: flex;
  /* Display as a flex container */
  z-index: 10;
  /* Set the z-index to 10 */
  transition-duration: 0.3s;
  /* Transition duration of 300ms */
}

/* Apply the class for large screens (lg:hidden) */
@media (min-width: 992px) {
  .navbarSmall {
    display: none;
    /* Hide on large screens */
  }
}



@media screen and (max-width: 768px) {
  .navbarwrapper h6 {
    font-size: 16px !important;
    color: white !important;
    padding-left: 0px;
    background-color: transparent;
    text-align: initial;
    margin-bottom: 0;
  }

  .navbarContainer {
    height: 70px;
    position: fixed;
    top: 0;
    z-index: 10;
  }
}