.inspired_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-top: 2rem;
  background-repeat: no-repeat;
  color: #101e3f;
  background-image: url("../../assets/Website_Banner.png");
  min-height: 280px;
  margin: 2rem;
}
.read_more {
  background-color: #101e3f;
  padding: 0.5rem 2rem;
  border-radius: 25px;
}
.read_more:hover {
  background-color: aliceblue;
  color: #101e3f;
  border: 3px solid #101e3f;
}
.inspired_title {
  color: #101e3f;
}
.inspired_subtitle {
  color: #27968e;
}
.inspired_button {
  margin-bottom: 0rem;
  margin-top: 4.5rem;
}
@media (max-width: 640px) {
  .inspired_button {
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
  .inspired_title {
    width: 90%;
    text-align: center;
    color: #b9c0d0;
  }
  .inspired_subtitle {
    color: #27968e;
    display: flex;
    text-align: center;
  }
}
