*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
a{
  text-decoration: none !important;
  color: black !important;
}
