.progress-multistep {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 14px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.white-bg {
height:100%;
align-items: center;
width:100%;
display: flex;
justify-content: center;
border:1px solid var(--gray);
border-radius:50%;
font-size:12px;
font-weight:600;
}