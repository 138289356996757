.subject_cards{
    margin-left: 7rem;
}

@media(max-width: 640px){
    .subject_cards{
        margin-left: 0px;
    }
    .columnn{
        display: flex;
        justify-content: center;
    }
}